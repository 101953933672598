/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {
	onNewsVersionListener,
	offNewsVersionListener,
} from 'pages/Versions/services';
import getMenuItems from './getMenuItems';
import StyledListItem, {List} from './styledListItem';
import Role from '../../Role';

class MenuItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleClick(e) {
		this.setState({[e]: !this.state[e]});
	}

	componentDidMount() {
		onNewsVersionListener();
	}

	componentWillUnmount() {
		offNewsVersionListener();
	}

	renderParentItem(item) {
		const isExternalLink = !!item?.externalTo
		const propsExternalLink = {
			component: "a",
			href: item.externalTo,
			target:"_blank",
			onClick: () => {}
		}

		return (
			<StyledListItem
				button
				key={item.id}
				onClick={this.handleClick.bind(this, item.name)}
				to={item.to}
				component={item.to && Link}
				{...isExternalLink ? propsExternalLink : {}}
			>
				<ListItemIcon>{item.icon}</ListItemIcon>
				<ListItemText primary={item.name} />
				{item.subitems &&
					(this.state[item.name] ? <ExpandLess /> : <ExpandMore />)}
			</StyledListItem>
		);
	}

	renderChildItems(list, item) {
		return (
			item.subitems && (
				<Collapse
					key={list.items.id}
					component="li"
					in={this.state[item.name]}
					timeout="auto"
					unmountOnExit
				>
					<List disablePadding>
						{item.subitems.map(subitem => {
							return (
								<Role key={subitem.id} roles={subitem.roles || item.roles}>
									<StyledListItem
										button
										{...{to: subitem.path}}
										component={Link}
										secondary
										className="secondary"
									>
										<ListItemIcon>{subitem.icon}</ListItemIcon>
										<ListItemText primary={subitem.name} />
									</StyledListItem>
								</Role>
							);
						})}
					</List>
				</Collapse>
			)
		);
	}

	render() {
		const items = getMenuItems(this.props.versions.total);
		return (
			<React.Fragment>
				{items.list.map(list => {
					return (
						<List
							key={list.id}
							subheader={<ListSubheader>{list.title}</ListSubheader>}
						>
							{list.items.map(item => {
								return (
									<Role key={item.id} roles={item.roles}>
										{this.renderParentItem(item)}
										{this.renderChildItems(list, item)}
									</Role>
								);
							})}
						</List>
					);
				})}
			</React.Fragment>
		);
	}
}

export default connect(({versions}) => ({
	versions,
}))(withRouter(MenuItem));
